import Vue from 'vue'
import VueRouter from 'vue-router'
import useAuth from '@/composables/useAuth'
import { USER_ROLES } from '@/enums/enums'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('@/views/auth/Logout.vue'),
  },
  {
    path: '/',
    name: 'home',
    redirect: 'dashboard',
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/exercises',
    name: 'exercises',
    component: () => import('@/views/exercises/Exercises.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: () => import('@/views/sessions/Sessions.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/group-assessments',
    name: 'group-assessments',
    component: () => import('@/views/groupAssessments/GroupAssessments.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/fitness-assessments',
    name: 'fitness-assessments',
    component: () => import('@/views/fitnessAssessments/FitnessAssessments.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/golf-assessments',
    name: 'golf-assessments',
    component: () => import('@/views/golfAssessments/GolfAssessments.vue'),
    meta: { authorize: [USER_ROLES.ADMIN] },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/Error403.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const { hasRole, state: auth } = useAuth()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta

  if (authorize) {
    if (to.name !== 'login' && !auth.user) {
      return next({ name: 'login', query: { redirectTo: to.fullPath } })
    }

    if (!hasRole(authorize)) {
      return next({ name: 'error-403', query: { redirectTo: to.fullPath } })
    }
  }

  next()
})

export default router
